import React from 'react'

const Tools = ({tool}) => {
  return (
    <div className='tool-logo col'>
      <a href={tool.url}><img src={tool.imageURL} alt={tool.name} /></a>
    </div>
  )
}

export default Tools
