import React from 'react'
import ContactMe from './contactMe';
import Projects from './projects';
import Skills from './skills';
const Main = ({data}) => {
    
  return (
    <main>
      <div id="about-me" className="container-fluid">
            <div className="row">

                <div className=" col-md-4 about-me-img"></div>
                <div className="col-md-8 about-me-text">
                    <h2 className="headers">Hello!</h2>
                    <p>I'm Ali Sedigh Moghadam, a tech enthusiast who likes to learn new things and work in multiple fields to gain cool experiences. Recently I got OCD in Computer Programming (CPP) from Seneca College with a 3.9 GPA. Currently, I'm doing the Honours Bachelor of Technology in the Software Development program at Seneca College.</p>
                    <p>You can contact me in the <a href="#contact-me">contact me</a> section. Also don't forget to check my <a href="https://www.linkedin.com/in/ali-sedigh-moghadam/">LinkedIn</a>.</p>
                    <h3 className="headers">Skills</h3>
                    <div className="container-fluid">
                        <div className='row'>
                          <div className='col d-flex' >
                            <div className='vr'></div>
                            <p>0%</p>
                          </div>
                          <div className='col d-flex justify-content-center'  >
                            <div className='vr'></div>
                            <p>50%</p>
                          </div>
                          <div className='col d-flex justify-content-end' >
                            <p>100%</p>
                            <div className='vr'></div>
                          </div>
                        </div>
                            {data.map((value,index)=>{
                                return (<Skills value={value} index={index}/>)
                            })}
                            
                        
                    </div>
                </div>
                
            </div>
            </div>
            <Projects/> 
            <ContactMe/>
    </main>
  )
}

export default Main
