
import emailjs from 'emailjs-com'
import React from 'react'
import { useState } from 'react'

const ContactMe = () => {
    const [showMessage,setShowMessage]=useState(false);
    const [input,setInput]=useState({
        fName:'',
        lName:'',
        email:'',
        message:'',
    })
    const handelInputChange=(e)=>{
        
        setInput(Object.assign({},input,{[e.target.name]:e.target.value}))
        
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        emailjs.init("W51KbjVgCfI9UBJyZ");
        const {fName,lName,email,message}=input;
        const contact={fname:fName,lname:lName,email:email,message:message};
        /*axios.post('http://localhost:8080/contact',contact)
        .then(()=>console.log('emailed'))
        .catch(err=>{
            console.error(err);
        });*/
        emailjs.send(process.env.REACT_APP_SERVICE_ID_CLIENT,process.env.REACT_APP_TEMPLATE_ID_CLIENT,contact)
        emailjs.send(process.env.REACT_APP_SERVICE_ID_HOST,process.env.REACT_APP_TEMPLATE_ID_HOST,contact)
        setShowMessage(true);
        setInput({
            fName:'',
            lName:'',
            email:'',
            message:'',
        })
    }
    
  return (
    <div className='container-fluid contact-section' id='contact-me'>
        <div className='container form-container'>
        <h2>Contact me</h2>
            <form onSubmit={handleSubmit} className='contact-me-form'>
            <div className=' row form-row'>
                <div className='col-md-6'>

                    <label htmlFor="fNameInput" className='form-label'>First name*</label>
                    <input name='fName' value={input.fName} type="text" className='form-control' id='fNameInput' onChange={handelInputChange} required/>
                </div>
                <div className='col-md-6'>

                    <label htmlFor="lNameInput" className='form-label'>Last name*</label>
                    <input name='lName' value={input.lName} type="text" className='form-control' id='lNameInput' onChange={handelInputChange} required />
                </div>
            </div>
            <div className=' row form-row'>
                <div className='col'>
                    <label htmlFor="emailInput" className='form-label'>Email*</label>
                    <input name='email' value={input.email} type="email" className='form-control' id='emailInput' onChange={handelInputChange} required />

                </div>
            </div>
            <div className=' row form-row'>
                <div className='col'>
                    <label htmlFor="messageInput" className='form-label'>Your message*</label>
                    <textarea name="message" value={input.message} id="messageInput" cols="40" rows="3" type='text' className='form-control md-textarea' onChange={handelInputChange} required></textarea>
                </div>
            </div>
            <div className='d-flex flex-wrap '>
            <button type='submit' className='btn btn-light '>Submit</button>
            {showMessage? <h3 >Thanks for your message!</h3>:null}
            

            </div>
            </form>
        </div>
        
    </div>
  )
}

export default ContactMe
