
import './App.css';
import NameHolder from './components/nameHolder';
import Main from './components/main';

function App() {
  
  
  const data=[{
    language:'C++',
    skill:90
  },{
    language:'C',
    skill:90
  },{
    language:'C#',
    skill:70
  },{
    language:'Java',
    skill:85
  },{
    language:'Javascript',
    skill:85
  },{
    language:'HTML/CSS',
    skill:90
  },{
    language:'SQL',
    skill:90
  },{
    language:'RPG',
    skill:70
  }]
  
  
  
  return (
    <div className="App">
      <NameHolder/>
      <Main data={data}/>
      
      
    </div>
  );
  
}

export default App;
