import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import allTools from "./docs/allTools.json";
import Tools from "./tools";
const Project = ({ data }) => {
  const [lan, setLan] = useState("");
  const [containsGif, setContainsGif] = useState(false);
  var toolsUsed = [];
  var imgUrl =
    "https://raw.githubusercontent.com/alisedighmoghadam/" +
    data.name +
    "/main/screenshots/1.png";
  var gifUrl =
    "https://raw.githubusercontent.com/alisedighmoghadam/" +
    data.name +
    "/main/screenshots/1.gif";
  var repUrl = "https://github.com/alisedighmoghadam/" + data.name;
  var readmeUrl =
    "https://raw.githubusercontent.com/alisedighmoghadam/" +
    data.name +
    "/main/README.md";
  function extarctLan(readme) {
    var regex = /<!--.*?-->/g;
    var str = regex.exec(readme);

    setContainsGif(str[0].includes("#gif#"));

    setLan(
      str[0]
        .replace(/^<!--|-->$/g, "")
        .replace(/^#*#$/g, "")
        .trim()
        .split(",")
    );
  }
  /*useEffect(()=>{
        axios.get(data.languages_url).then(res=>{
            setLan(Object.keys(res.data));
            
        })
    },[data.languages_url])*/
  useEffect(() => {
    axios.get(readmeUrl).then((res) => extarctLan(res.data));
  }, [readmeUrl]);

  allTools.tools.forEach((element) => {
    if (lan.includes(element.name)) {
      toolsUsed.push(element);
    }
  });

  return (
    <div className="card col">
      <img
        className="card-img-top"
        src={containsGif ? gifUrl : imgUrl}
        alt=""
      />
      <div className="card-body">
        <div className="card-body-inside container row">
          <h2 className="card-title col">{data.name}</h2>
          <div className="container row tool-container">
            {toolsUsed.map((value) => {
              return <Tools tool={value} />;
            })}
          </div>
        </div>
        <p className="card-text">{data.description}</p>
        <a
          href={repUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-light btn-card "
        >
          Check it on GitHub
        </a>
      </div>
    </div>
  );
};

export default Project;
