import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react';
import Project from './project';
const Projects = () => {
    const url='https://api.github.com/users/alisedighmoghadam/repos';
    const [data,setData]=useState();
    useEffect(()=>{
        axios.get(url).then(res=>{
            setData(res.data)
            
        })
    },[url]);
    if(data!=null){
        
        return (
        <div id='projects' >
            <h2 className='project-section-title'>Projects</h2>
            <div className='container'>
                <div className='row'>

                
                    {data.map((value)=>{
            
                    return <Project data={value}/>
                    })}
                </div>
            </div>
        </div>
      )
    }
}

export default Projects
