import React from 'react'

const Skills = ({value,index}) => {
    var stylePercentage=()=>{
        var tmp=value.skill.toString()+'%';
        
        return tmp;
    }
    return (
    <div className='row'>
      <div className="col skills-div">
            <div className="skills-name">
                <p>{value.language}</p> 
            </div>
            <div className='progress' style={{background:'none',height:'25px'}}>
                <div className='progress-bar' role='progressbar'  aria-valuenow={value.skill} aria-valuemin='0' aria-valuemax="100" style={{width:stylePercentage(),backgroundColor:'#8EA7DA'}} ></div>
            </div>                    
        </div>
    </div>
  )
}

export default Skills
