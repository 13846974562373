import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import Navbar from './navbar'

const NameHolder = () => {
    const[width,setWidth]=useState('');
    const[inlineHeight,setInlineHeight]=useState({inlineHeight:'0px'});
    const[left,setLeft]=useState();
    const[right,setRight]=useState({right:'-600px'});
    
    let logos={
        gitHubPath:require('../images/logo/GitHub-Mark-64px.png'),
        instaPath:require('../images/logo/instagram-logo.png'),
        twitterPath:require('../images/logo/twitter-logo-on-black-background.png'),
        telePath:require('../images/logo/png.monster-258-170x170.png'),
        linkedInPath:require('../images/logo/kisspng-computer-icons-linkedin-logo-insta-5ad1806070af61.2187312815236793284616.png')
    }
    
    useEffect(()=>{
        window.addEventListener('scroll',action)
    },[])
    const action=()=>{
        var scrPos=window.scrollY;
        
    var _width=100
    var tmp
    _width=_width-(scrPos/2);
    setWidth({width:_width.toString()+'%'});
    setRight({right:((scrPos*6)-600).toString()+'px'})
    if(scrPos<100){
        tmp=scrPos/10;
        setInlineHeight({lineHeight: tmp.toString()+'vw'});
    }else if(scrPos>=100 && scrPos<200){
        setRight({right:'0px'})
    }else if(scrPos>=200){
        setRight({right:'0px'})
        tmp =scrPos-200;
        if(tmp>0&&tmp<=20){
            tmp=0
        }
        setLeft({left: '-'+tmp.toString()+'px'});
        
    }
    }
    
  
    return (
    <header>
    <div  className="home-image-div">
        <Navbar/>
        <div id="home" className="Name-div">
            <h1 id="name-h1" style={{ ...left,...width,...inlineHeight}}>Ali Sedigh Moghadam</h1>
        </div>
        <div className='logo-container container d-flex '>
            <div className='flex-wrap d-flex justify-content-center align-items-center row row-cols-2'>

                <a className='col' href="https://www.linkedin.com/in/ali-sedigh-moghadam/" target="_blank" rel="noopener noreferrer"><img src={logos.linkedInPath} alt="" /></a>
                <a className='col' href="https://github.com/alisedighmoghadam" target="_blank" rel="noopener noreferrer"><img src={logos.gitHubPath} alt="" /></a>
                <a className='col' href="https://www.instagram.com/alisedighmoghadam/" target="_blank" rel="noopener noreferrer"><img src={logos.instaPath} alt="" /></a>
                <a className='col' href="https://twitter.com/asedighmoghadam" target="_blank" rel="noopener noreferrer"><img src={logos.twitterPath} alt="" /></a>
            </div>
    </div>
    </div>
        <div className='occupation-section' style={{...right}}>
            <h3>Software Development</h3>
            <h3>Web Development</h3> 
            <h3>Photography</h3>
        </div>
    </header>
        
    
  )
}

export default NameHolder
