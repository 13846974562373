import React, { useEffect, useState } from 'react'
import '../App.css';
const Navbar = () => {
    const [showNav,setShowNav]=useState(true);
    var prevScrollpos = window.pageYOffset;
    const action=()=>{
        var currentScrollPos =window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            setShowNav(true)
          } else {
            setShowNav(false)
        }
        prevScrollpos = currentScrollPos;
        return showNav;
    }
    useEffect(()=>{
        window.addEventListener("scroll",action);
        return ()=> window.removeEventListener("scroll",action);
    })
    
    
    return (
    <div id="navbar" style={{top:showNav ? '0px':'-120px'}} >
        <div className='navbar'  >
                    <ul className="nav">
                        <li className="nav-link active"><a href="#home">Home</a></li>
                        <li className="nav-link"><a href="#about-me" >About me</a></li>
                        <li className="nav-link"><a href="#projects">Projects</a></li>
                        <li className="nav-link"><a href="#contact-me">Contact me</a></li>
                    </ul>
        </div>
        <hr/>      

    </div>
  )
}

export default Navbar
